import { Component, EventEmitter, Input, Output } from '@angular/core';
import Document from '@shared/models/Document';
import { IDeleteFile, IFileUpload } from '@shared/components/file-upload/file-upload.component';
import Media, { VISIBILITY_PRIVATE } from '@shared/models/Media';
import { MediaService } from '@shared/requests/media.service';
import { ToastService } from '@shared/services/toast.service';
import { DocumentService } from '@shared/translations/document.service';
import { StatusService } from '@shared/translations/status.service';
import { DocumentRequest } from '@shared/requests/document.service';
import { FILE_TYPE } from '@shared/constant/mime_format.constant';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { getFileFromBase64 } from '@shared/functions/get-file-from-b64.function';
import { DocumentsStatusEnum } from '@shared/enums/documents-status.enum';
import { catchError, of, tap } from 'rxjs';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
})
export class FileInputComponent {
  constructor(
    private http: MediaService,
    private toast: ToastService,
    private httpDocument: DocumentRequest,
    public statusService: StatusService,
    public documentService: DocumentService
  ) { }

  @Output() public fileUploaded = new EventEmitter<Media | boolean>();

  @Input() public document!: Document;

  /**
   * If component should accept multiple files.
   */
  @Input() public isMultiple!: boolean;

  public open = false;

  public uploads: IFileUpload[] = [];

  public imageURL!: '' | string;

  public onFileDropped(files: FileList): void {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < files.length; ++i) {
      if (
        files[i].size > 50000000 ||
        (files[i].type !== 'image/jpeg' && files[i].type !== 'image/png' && files[i].type !== 'application/pdf')
      ) {
        if (files[i].size > 50000000) {
          this.toast.toastError('FILE_SIZE_ERROR');
        } else {
          this.toast.toastError('ONLY_EXTENSIONS_ACCEPTED');
        }
      } else if (files[i].name.length > 60) {
        const splitName = files[i].name.split('.');
        const extension = splitName[splitName.length - 1];
        const newName = `${files[i].name.slice(0, 60 - (extension.length + 1))}.${extension}`;
        const blob = files[i].slice(0, files[i].size, files[i].type);
        const newFile = new File([blob], newName, { type: files[i].type });
        this.pushFileToUploads(newFile);
      } else {
        this.pushFileToUploads(files[i]);
      }
    }
  }

  async takePicture() {
    const image = await Camera.getPhoto({
      source: CameraSource.Photos,
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Base64,
    });

    if (!image?.base64String) {
      return;
    }

    this.imageURL = image.base64String;

    if (
      image.format !== FILE_TYPE.png.format &&
      image.format !== FILE_TYPE.jpeg.format &&
      image.format !== FILE_TYPE.pdf.format
    ) {
      this.toast.toastError('ONLY_EXTENSIONS_ACCEPTED');
      return;
    }

    getFileFromBase64(`data:${image.format};base64,${this.imageURL}`, image.format).then((file) => {
      this.pushFileToUploads(file);
    });
  }

  public onFileUploaded(media: Media | boolean): void {
    if (typeof media !== 'boolean') {
      if (!media) {
        this.document.isValid = false;
        this.document.status = DocumentsStatusEnum.FILE_ERROR;
        return;
      }
      this.fileUploaded?.emit(media);
      media.deletable = true;
      this.document.medias.push(media);
      if (this.document.isValid !== false) {
        this.document.status = DocumentsStatusEnum.WAITING_APPROVAL;
      }

      this.uploads.forEach((u, i) => {
        if (u.file.name === media.originalName) {
          this.uploads.splice(i, 1);
        }
      });
    }
  }

  public delete(id: string): void {
    if (this.document.status !== 'validated') {
      if (this.document.medias.length === 1) {
        this.document.status = 'provide';
      }
      this.deleteFile(id);
    }
  }

  public deleteUploaded(upload: IDeleteFile) {
    if (upload.success) {
      this.deleteFile(upload.id);
    } else {
      this.uploads.splice(upload.index, 1);
      this.toast.toastSuccess('DELETE_FILE_SUCCES');
    }

    if (!this.isDocumentInvalid()) {
      this.document.status =
        this.document.medias.length === 0 ? DocumentsStatusEnum.PROVIDE : DocumentsStatusEnum.WAITING_APPROVAL;
      this.document.isValid = true;
    }
  }

  private isDocumentInvalid(): boolean {
    return !!this.uploads?.find((upload: IFileUpload) => upload.isValid === false);
  }

  private deleteFile(id: string): void {
    this.http
      .delete(id)
      .pipe(
        tap(() => {
          const test = { ...this.document };
          test.medias = [];
          this.document = test;
          this.toast.toastSuccess('DELETE_FILE_SUCCES');
        }),
        catchError((error) => {
          this.toast.toastError('DELETE_FILE_ERROR');
          return of(error);
        })
      )
      .subscribe();
  }

  /**
   * Push file to list of upload.
   *
   * @param file File object
   */
  private pushFileToUploads(file: File): void {
    const up: IFileUpload = {
      file,
      visibility: VISIBILITY_PRIVATE,
      isValid: true,
    };
    this.uploads.push(up);
  }
}
