import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { StatItemComponent } from '@shared/components/stat-item/stat-item.component';
import { SortableItemComponent } from '@shared/components/sortable-item/sortable-item.component';
import { PaginationComponent } from '@shared/components/pagination/pagination.component';
import { ClipboardModule } from 'ngx-clipboard';
import { BooleanFormComponent } from '@shared/form/boolean-form/boolean-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UserComponent } from '@shared/components/user/user.component';
import { DropFileDirective } from '@shared/directives/drop-file.directive';
import { FileUploadComponent } from '@shared/components/file-upload/file-upload.component';
import { FileInputComponent } from '@shared/components/file-input/file-input.component';
import { BondComponent } from '@shared/components/items/bond/bond.component';
import { LinkComponent } from '@shared/components/items/link/link.component';
import { TransactionComponent } from '@shared/components/items/transaction/transaction.component';
import { TylltComponent } from '@shared/components/tyllt/tyllt.component';
import { PlaceholderComponent } from '@shared/components/items/placeholder/placeholder.component';
import { LinkPlaceholderComponent } from '@shared/components/items/link-placeholder/link-placeholder.component';
import { DocumentComponent } from '@shared/components/document/document.component';
import { LoadButtonComponent } from '@shared/components/load-button/load-button.component';
import { AbstractIonInitPage } from '@shared/abstract/AbstractIonInitPage';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyComponent } from '@shared/components/empty/empty.component';
import { PropertyComponent } from '@shared/components/items/property/property.component';
import { InformationsComponent } from '@shared/components/informations/informations.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { LuxonModule } from 'luxon-angular';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import {
  ShortLangPickerComponent
} from '@shared/components/short-lang-picker/short-lang-picker.component';
import { ChangeSpaceComponent } from '@shared/modals/change-space/change-space.component';
import {
  LongLangPickerComponent
} from '@shared/components/long-lang-picker/long-lang-picker.component';
import {
  InformationsDetailsComponent
} from '@shared/components/informations-details/informations-details.component';
import { InvoicesComponent } from '@shared/components/items/invoices/invoices.component';
import { OfferCardComponent } from '@shared/components/offer-card/offer-card.component';
import { IconComponent } from '@shared/components/items/payment-link/icon/icon.component';
import {
  PropertyIconComponent
} from '@shared/components/items/property/icon/property-icon.component';
import { SocietyComponent } from '@shared/components/profil/society/society.component';
import { ChangeEmailAuthComponent } from '@payment/components/change-email-auth/change-email-auth.component';
import { NewAccountComponent } from '@payment/components/new-account/new-account.component';
import { ValidEmailAuthComponent } from '@payment/components/valid-email-auth/valid-email-auth.component';
import { ProfilComponent } from '@auth/components/profil/profil.component';
import { PaymentLinkCrudComponent } from '@owner/modals/payment-link-crud/payment-link-crud.component';
import { KeyboardEnterDirective } from './directives/keyboard-enter.directive';
import { TooltipDirective } from './directives/tooltip.directive';
import { DeleteCardComponent } from './modals/delete-card/delete-card.component';
import { DragDropDirective } from './directives/drag-drop.directive';
import { LangPickerComponent } from './components/lang-picker/lang-picker.component';
import { GeneralComponent } from './components/profil/general/general.component';
import { LegalDocumentComponent } from './components/profil/legal-document/legal-document.component';
import { SettingsComponent } from './components/profil/settings/settings.component';
import { BankDetailsComponent } from './components/profil/bank-details/bank-details.component';
import { BandeauComponent } from './components/bandeau/bandeau.component';
import { MenuProfilComponent } from './components/profil/menu/menu-profil.component';
import { CustomDateTimeToFormatPipe } from './pipe/CustomDateTimeToFormatPipe.pipe';
import { AmLocalePipe } from './pipe/AmLocalePipe.pipe';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { InputPasswordComponent } from './components/input-password/input-password.component';
import { PartnerComponent } from './components/profil/partner/partner.component';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';

@NgModule({
  declarations: [
    AbstractIonInitPage,
    StatItemComponent,
    SortableItemComponent,
    PaginationComponent,
    BooleanFormComponent,
    UserComponent,
    DropFileDirective,
    FileUploadComponent,
    FileInputComponent,
    BondComponent,
    LinkComponent,
    TransactionComponent,
    TylltComponent,
    PlaceholderComponent,
    LinkPlaceholderComponent,
    DocumentComponent,
    LoadButtonComponent,
    KeyboardEnterDirective,
    TooltipDirective,
    EmptyComponent,
    PropertyComponent,
    DeleteCardComponent,
    DragDropDirective,
    LangPickerComponent,
    ShortLangPickerComponent,
    LongLangPickerComponent,
    InformationsComponent,
    LegalDocumentComponent,
    SettingsComponent,
    GeneralComponent,
    SocietyComponent,
    BankDetailsComponent,
    BandeauComponent,
    MenuProfilComponent,
    CustomDateTimeToFormatPipe,
    AmLocalePipe,
    DatePickerComponent,
    InputPasswordComponent,
    PartnerComponent,
    LoadingScreenComponent,
    ChangeSpaceComponent,
    InformationsDetailsComponent,
    InvoicesComponent,
    OfferCardComponent,
    ChangeEmailAuthComponent,
    NewAccountComponent,
    ValidEmailAuthComponent,
    ProfilComponent,
    PaymentLinkCrudComponent
  ],
  exports: [
    StatItemComponent,
    SortableItemComponent,
    PaginationComponent,
    BooleanFormComponent,
    UserComponent,
    DropFileDirective,
    FileUploadComponent,
    FileInputComponent,
    BondComponent,
    LinkComponent,
    TransactionComponent,
    TylltComponent,
    PlaceholderComponent,
    LinkPlaceholderComponent,
    DocumentComponent,
    LoadButtonComponent,
    KeyboardEnterDirective,
    TooltipDirective,
    EmptyComponent,
    PropertyComponent,
    DeleteCardComponent,
    DragDropDirective,
    LangPickerComponent,
    ShortLangPickerComponent,
    LongLangPickerComponent,
    InformationsComponent,
    LegalDocumentComponent,
    SettingsComponent,
    GeneralComponent,
    SocietyComponent,
    BankDetailsComponent,
    BandeauComponent,
    MenuProfilComponent,
    LuxonModule,
    CustomDateTimeToFormatPipe,
    AmLocalePipe,
    DatePickerComponent,
    NgxMaskDirective,
    NgxMaskPipe,
    InputPasswordComponent,
    PartnerComponent,
    LoadingScreenComponent,
    ChangeSpaceComponent,
    InformationsDetailsComponent,
    InvoicesComponent,
    OfferCardComponent,
    ChangeEmailAuthComponent,
    NewAccountComponent,
    ValidEmailAuthComponent,
    ProfilComponent,
    PaymentLinkCrudComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ClipboardModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    ReactiveFormsModule,
    NgSelectModule,
    LuxonModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgOptimizedImage,
    IconComponent,
    PropertyIconComponent,
  ],
  providers: [
    provideNgxMask()
  ],
})
export class SharedModule { }
